.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff; /* Cor de fundo branca */
  padding: 20px;
  color: #333; /* Cor do texto */
}

.App-logo-fragus {
  height: 7vmin;
  pointer-events: none;
}

.Who {
 max-width: 400px;
 text-align: center;
 margin: 0 auto;
}